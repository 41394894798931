import React from "react"

import SEO from "../components/seo"

import loadable from '@loadable/component'

const SectionOne = loadable(() => import('../components/section/free/sectionOne'))
const SectionTwo = loadable(() => import('../components/section/free/sectionTwo'))
const SectionTheree = loadable(() => import('../components/section/free/sectionTheree'))
const SectionFour = loadable(() => import('../components/section/free/sectionFour'))
const SectionFive = loadable(() => import('../components/section/free/sectionFive'))
const SectionSix = loadable(() => import('../components/section/free/sectionSix'))
const SectionSeven = loadable(() => import('../components/section/free/sectionSeven'))
const Faq = loadable(() => import('../components/section/free/faq'))

class FreeEbook extends React.Component {

    render() {
    
        return (
            <React.Fragment>
                <SEO title="Free Ebook"/>
                <SectionOne></SectionOne>
                <SectionTwo></SectionTwo>
                <SectionTheree></SectionTheree>
                <SectionFour></SectionFour>
                <SectionFive></SectionFive>
                <SectionSix></SectionSix>
                <SectionSeven></SectionSeven>
                <Faq></Faq>
            </React.Fragment>
        )
    }
}
export default FreeEbook